import { Button, ChevronDownIcon, FileUploadZone, Form, styled } from '@skywatch/ui'
import { ReportFormObj, ReportFormType, ReportTypeList } from './types'
import { Controller, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { States, USStateTypes } from '@drive/shared'
import { useEffect, useRef, useState } from 'react'

export type ReportFormProps = {
  onSubmit: (data: ReportFormType) => Promise<void>
  getActiveStates: () => Promise<string[]>
}

export default function ReportForm({ onSubmit, getActiveStates }: ReportFormProps) {
  const dropdownRef = useRef<HTMLDivElement>(null)

  const {
    control,
    setValue,
    register,
    handleSubmit,
    watch,
    unregister,
    formState: { errors },
  } = useForm<ReportFormType>({
    resolver: zodResolver(ReportFormObj),
  })
  const reportTypeWatch = watch('reportType')
  const [selectedStates, setSelectedStates] = useState<string[]>([])
  const [dropdownOpen, setDropdownOpen] = useState(false)
  const [activeStates, setActiveState] = useState<string[][]>([])
  const allSelected = selectedStates.length === activeStates.length

  function toggleSelectAll() {
    const newSelection = allSelected ? [] : activeStates.map(([key]) => key)
    setSelectedStates(newSelection)
    setValue('states', newSelection as USStateTypes[])
  }

  function toggleState(state: string) {
    setSelectedStates(prev => {
      const newSelection = prev.includes(state) ? prev.filter(s => s !== state) : [...prev, state]
      setValue('states', newSelection as USStateTypes[])
      return newSelection
    })
  }

  async function handleCgiChoose() {
    const res = await getActiveStates()
    const states = res.map(code => [code, States[code as USStateTypes]])
    setActiveState(states)
  }

  useEffect(() => {
    if (reportTypeWatch === 'CGI') {
      handleCgiChoose()
    }

    if (reportTypeWatch !== 'CGI') {
      unregister('states')
    }

    if (reportTypeWatch === 'Claims') {
      unregister('startDate')
      unregister('endDate')
    }

    if (reportTypeWatch !== 'Claims') {
      unregister('file')
    }
  }, [reportTypeWatch, unregister])

  useEffect(() => {
    function handleClickOutside(event: MouseEvent) {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target as Node)) {
        setDropdownOpen(false)
      }
    }
    document.addEventListener('mousedown', handleClickOutside)
    return () => document.removeEventListener('mousedown', handleClickOutside)
  }, [])

  return (
    <>
      <div className="p-6 font-semibold">Generate report</div>
      {/* Divider */}
      <div className="mb-4 hidden w-full border-b border-gray-300 md:col-span-6 md:block" />
      <form id="report-form" onSubmit={handleSubmit(onSubmit)} className="font-poppins w-6/12 space-y-4 pl-6">
        {/* Report Type */}
        <Form.Control>
          <Form.Label>Report type</Form.Label>
          <Form.Select className={errors?.reportType && 'input-error'} {...register('reportType', {})}>
            {ReportTypeList.map(type => (
              <option value={type} key={type}>
                {type}
              </option>
            ))}
          </Form.Select>
          {errors.reportType && <Form.Error>{errors.reportType.message}</Form.Error>}
        </Form.Control>

        {reportTypeWatch === 'Claims' ? (
          <Form.Control className="grow">
            <FileUploadZone {...register('file')} fileType="text/csv" uploadText="CSV format" />
            {errors.file && <Form.Error>{errors.file.message}</Form.Error>}
          </Form.Control>
        ) : (
          <>
            {/* State Selection */}
            {reportTypeWatch === 'CGI' && (
              <Form.Control>
                <Form.Label>State</Form.Label>
                <Controller
                  control={control}
                  name="states"
                  render={() => (
                    <div className="input relative w-full pr-1.5 " ref={dropdownRef}>
                      <div
                        className="flex cursor-pointer items-center justify-between truncate "
                        onClick={() => setDropdownOpen(prev => !prev)}
                      >
                        <span>
                          {selectedStates.length > 0
                            ? selectedStates.map(code => States[code as USStateTypes] || code).join(', ')
                            : 'Select states'}
                        </span>
                        <span>
                          <ChevronDownIcon className="h-4 stroke-gray-500" strokeWidth={2} />
                        </span>
                      </div>
                      {dropdownOpen && (
                        <ul className="absolute mt-1 max-h-60 w-full overflow-y-auto rounded border bg-white p-6">
                          <li className="pb-2 text-gray-900">
                            <input
                              className="checkbox"
                              type="checkbox"
                              checked={allSelected}
                              onChange={toggleSelectAll}
                            />{' '}
                            Select All
                          </li>
                          {activeStates.map(([key, label]) => (
                            <li key={key} className="flex items-center py-2 text-gray-900">
                              <input
                                className="checkbox"
                                type="checkbox"
                                checked={selectedStates.includes(key)}
                                onChange={() => toggleState(key)}
                              />
                              <span className="ml-2">{label}</span>
                            </li>
                          ))}
                        </ul>
                      )}
                    </div>
                  )}
                />
                {errors.states && <Form.Error>{errors.states.message}</Form.Error>}
              </Form.Control>
            )}

            <div className="flex flex-row space-x-2">
              {/* Starting Date */}
              <Form.Control className="grow">
                <Form.Label>Starting date</Form.Label>
                <input
                  className={`input ${errors?.startDate ? 'input-error' : ''}`}
                  {...register('startDate')}
                  placeholder="Select date"
                  type="date"
                />
                {errors.startDate && <Form.Error>{errors.startDate.message}</Form.Error>}
              </Form.Control>

              {/* End Date */}
              <Form.Control className="grow">
                <Form.Label>End date</Form.Label>
                <input
                  className={`input ${errors?.endDate ? 'input-error' : ''}`}
                  {...register('endDate')}
                  placeholder="Select date"
                  type="date"
                />
                {errors.endDate && <Form.Error>{errors.endDate.message}</Form.Error>}
              </Form.Control>
            </div>
          </>
        )}

        <Button buttonType="primary" size="small" className="w-6/12 py-2" type="submit">
          Generate report
        </Button>
      </form>
    </>
  )
}
