import { createEndpoint, createEndpointV2 } from '@skywatch/core'
import { OdometersGridSchema, PoliciesGridSchema, QuoteGridSchema, UsersSchema } from '~/models'
import { AffiliatesGridSchema } from '~/models/affiliate'

const { VITE_VOOM_API_PATH } = import.meta.env

export const getPoliciesGridDataEndpoint = createEndpoint(`${VITE_VOOM_API_PATH}/dashboard/getPolicyGridDataByQuery`, {
  method: 'GET',
  useAuthorization: true,
  validation: {
    responseSchema: PoliciesGridSchema,
  },
  cacheId: 'policyGrid',
})

export const getOdometersGridDataEndpoint = createEndpoint(
  `${VITE_VOOM_API_PATH}/dashboard/getOdometerGridDataByQuery`,
  {
    method: 'GET',
    useAuthorization: true,
    validation: {
      responseSchema: OdometersGridSchema,
    },
    cacheId: 'odometerGrid',
  }
)

export const getAffiliateGridDataEndpoint = createEndpointV2({
  method: 'GET',
  urlTemplate: 'api/dashboard/getAffiliateGridDataByQuery',
  useAuthorization: true,
  validations: {
    response: AffiliatesGridSchema,
  },
  cacheId: 'affiliateGrid',
})

export const getEmailsEndpoint = createEndpoint(`${VITE_VOOM_API_PATH}/dashboard/getApplicationEmails`, {
  method: 'GET',
  useAuthorization: true,
  validation: {
    responseSchema: UsersSchema,
  },
})

export const getPoliciesEndpoint = createEndpoint(`${VITE_VOOM_API_PATH}/dashboard/getPoliciesEmails`, {
  method: 'GET',
  useAuthorization: true,
  validation: {
    responseSchema: UsersSchema,
  },
})
export const getQuoteGridDataEndpoint = createEndpoint(`${VITE_VOOM_API_PATH}/dashboard/getQuoteData`, {
  method: 'GET',
  useAuthorization: true,
  validation: {
    responseSchema: QuoteGridSchema,
  },
  cacheId: 'quoteGrid',
})

export const getQuoteReportEndpoint = createEndpoint(`${VITE_VOOM_API_PATH}/dashboard/getQuoteReport`, {
  method: 'POST',
  useAuthorization: true,
})
