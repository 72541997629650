import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'
import { Button, CloseIcon, FileUploadZone, Form } from '@skywatch/ui'
import { Wait } from '@drive/shared'
import { UploadOdometerType } from '~/forms'
import UploadOdometer from '~/forms/UploadOdometer'
import { useOdometerManager } from '../hooks'
import { getCommercialMiles } from '@drive/shared'
import { FetchCommercialMilesRequest } from '../../../../../../modules/policy/src/store'

type ManualOdometerUploadProps = {
  policyId: string
  onClose: (needRender: boolean) => void
}

export default ({ policyId, onClose }: ManualOdometerUploadProps) => {
  const {
    state: { odometerToUpload, status },
    fetchPolicyOdometerToUpload,
    uploadOdometer,
    createOdometerTransaction,
    setStoreDefaultValue,
    fetchPolicyOdometerData,
  } = useOdometerManager()
  const {
    register,
    watch,
    handleSubmit,
    setValue,
    formState: { errors },
  } = useForm<UploadOdometerType>({ resolver: zodResolver(UploadOdometer) })
  const [previousMiles, setPreviousMiles] = useState<number | undefined>()
  const [error, setError] = useState('')
  const [loader, setLoader] = useState(false)
  const vehicleId = watch('vehicleId')

  const getData = async (policyId: string) => {
    await fetchPolicyOdometerToUpload(policyId)
  }

  const handleUpload = async (data: UploadOdometerType) => {
    const previousMilesValue = previousMiles ?? 0
    if (data.miles < previousMilesValue) {
      return setError(`Miles must be greater than previous miles: ${previousMilesValue.toLocaleString('en-US')} `)
    }
    /*if (data.miles - previousMilesValue < data.commercialMiles) {
      return setError(
        `Based on the entered odometer reading - max commercial mile value is: ${(
          data.miles - previousMilesValue
        ).toLocaleString('en-US')} `
      )
    }*/

    setError('')
    const currentVehicle = odometerToUpload.find(v => v.vehicleId === vehicleId)
    if (currentVehicle) {
      setLoader(true)
      if (currentVehicle?.transactionItemId) {
        await uploadOdometer(
          data.file[0],
          policyId,
          currentVehicle.transactionItemId,
          vehicleId,
          data.miles,
          data.commercialMiles
        )
      } else {
        await createOdometerTransaction(data.file[0], policyId, vehicleId, data.miles, data.commercialMiles)
      }
      setStoreDefaultValue()
      await fetchPolicyOdometerData(policyId)
      onClose(true)
      setLoader(false)
    } else {
      setError("Can't upload odometer")
    }
  }

  useEffect(() => {
    if (policyId !== undefined) {
      getData(policyId)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [policyId])

  useEffect(() => {
    if (vehicleId) {
      const currentVehicle = odometerToUpload.find(v => v.vehicleId === vehicleId)
      setPreviousMiles(currentVehicle?.lastValidationValue)
      setValue('commercialMiles', 0)

      /*  const getVehicleCommercialMiles = async () => {
        const request = {
          vehiclesId: [vehicleId],
          toDate: new Date(),
        } as FetchCommercialMilesRequest

        const res = await getCommercialMiles(request, { pathParams: { policyId } })
        setValue('commercialMiles', res.data.vehiclesCommercialMiles[vehicleId])
        setLoader(false)
      }
      if (currentVehicle?.lastValidationValue !== undefined) {
        setLoader(true)
        getVehicleCommercialMiles()
      }*/
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [vehicleId])

  return (
    <>
      <Form onSubmit={handleSubmit(handleUpload)}>
        <div className="space-y-5">
          <div className="flex justify-between text-base font-semibold text-gray-900">
            <div></div>
            <div>Manual Odometer Upload</div>
            <div onClick={() => onClose(false)} className="cursor-pointer ">
              <CloseIcon stroke="#667085" />
            </div>
          </div>
          <FileUploadZone {...register('file')} fileType="image/*" uploadText="SVG, PNG, JPG or GIF (max. 800x400px)" />
          {errors.file && <div className="text-error-500 text-xs">{(errors.file as any)?.message}</div>}
          <select
            defaultValue={''}
            className={`w-full rounded-md border-2 border-gray-200 text-sm text-gray-500 ${
              errors?.vehicleId ? 'border-error-500' : ' border-gray-200'
            }`}
            {...register('vehicleId')}
          >
            <option value="" disabled>
              Vehicle
            </option>
            {odometerToUpload.map(v => (
              <option key={v.vehicleId} value={v.vehicleId}>
                {v.vehicle}
              </option>
            ))}
          </select>
          <Form.Label className="hidden h-3 text-sm md:block">Odometer Value</Form.Label>
          <input
            {...register('miles', { valueAsNumber: true })}
            className={`first-line w-full rounded-md border-2  text-sm text-gray-500 ${
              errors?.miles ? 'border-error-500' : ' border-gray-200'
            }`}
            type="number"
            placeholder="Odometer Value"
          />
          {errors?.miles && <div className="text-error-500 text-xs">{errors?.miles.message?.toString()}</div>}

          <Form.Label className="hidden h-3 text-sm md:block">Commercial Miles Value</Form.Label>
          <input
            {...register('commercialMiles', { valueAsNumber: true })}
            className={`first-line w-full rounded-md border-2  text-sm text-gray-500 ${
              errors?.commercialMiles ? 'border-error-500' : ' border-gray-200'
            } ${previousMiles === undefined && 'bg-gray-50'}  `}
            type="number"
            placeholder="Commercial Miles Value"
            disabled={true}
          />

          {vehicleId && previousMiles !== undefined && (
            <div className="text-xs text-gray-400">
              Last reported and approved: {(previousMiles ?? 0).toLocaleString('en-US')} miles
            </div>
          )}
          {vehicleId && previousMiles === undefined && (
            <div className="text-xs text-gray-400">No value - Initial odometer upload</div>
          )}
          {error && <div className="text-error-500 text-xs">{error}</div>}
          <div className="mt-4">
            <Button type="submit" buttonType={'primary'} className="w-full">
              Approve
            </Button>
          </div>
        </div>
      </Form>
      <Wait visible={status === 'busy' || loader} />
    </>
  )
}
