import { USStateKeys } from '@drive/shared'
import { z } from 'zod'

export const ReportTypeList = ['CGI', 'Bordereaux', 'Verisk', 'Claims', 'Affiliates'] as const
export type ReportTypeListType = (typeof ReportTypeList)[number]

export const ReportFormObj = z
  .object({
    states: z
      .array(z.enum(USStateKeys), {
        errorMap: () => ({ message: 'Required' }),
      })
      .refine(
        states => {
          // Check if more than one state and TX is included
          if (states.length > 1 && states.includes('TX')) {
            return false
          }
          return true
        },
        {
          message: 'TX report cannot be generated with other states.',
        }
      )
      .optional(),
    startDate: z.coerce
      .date({
        errorMap: () => ({ message: 'Required' }),
      })
      .optional(),
    endDate: z.coerce
      .date({
        errorMap: () => ({ message: 'Required' }),
      })
      .optional(),
    reportType: z.enum(ReportTypeList, {
      errorMap: () => ({ message: 'Required' }),
    }),
    file: z
      .any()
      .refine(files => {
        return files[0]?.size > 0
      }, `Required`)
      .optional(),
  })
  .refine(
    schema => {
      if (schema.endDate && schema.startDate) {
        return schema.endDate > schema.startDate
      }
      return true
    },
    {
      message: 'Start date must be before end date',
      path: ['startDate'],
    }
  )

export type ReportFormType = z.infer<typeof ReportFormObj>
